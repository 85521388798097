import '../App.css';
import Navigator from './Navigator';

function Home() {
  return (
    <div className='container'>
      <video width="100%" height="auto" autoPlay loop muted controls>
        <source src={process.env.PUBLIC_URL + '/images/thisisurine_short.mp4'} type='video/mp4'></source>
        Sorry, your browser doesn't support embeded videos.
      </video>
      <Navigator backgroundColor={'transparent'}></Navigator>
    </div>
  );
}

export default Home;
