import '../App.css';

function Footer() {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return(
        <div className='footer'>
            <div className='footerContent cursor' onClick={scrollToTop}>
                Top
            </div> 
        </div>    
    );
}

export default Footer;