import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Work from './components/Work';
import About from './components/About';
import News from './components/News';
import Archive from './components/Archive';
import Contact from './components/Contact';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/work" element={<Work />} />
      <Route path="/about" element={<About />} />
      <Route path="/news" element={<News />} />
      <Route path="/archive" element={<Archive />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default App;
