const uwc2 = [
    {
        label: "Urine Ware Collection Ⅱ",
        imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2019_1.png',
        description: ""
    },
    {
        label: "Urine Ware Collection Ⅱ",
        imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2019_2.png',
        description: "설명"
    },
    {
        label: "Urine Ware Collection Ⅱ",
        imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2019_3.png',
        description: "설명"
    },
    {
        label: "Urine Ware Collection Ⅱ",
        imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2019_4.png',
        description: "설명"
    },
    {
        label: "Urine Ware Collection Ⅱ",
        imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2019_5.png',
        description: "설명"
    },
    {
        label: "Urine Ware Collection Ⅱ",
        imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2019_6.png',
        description: "설명"
    },
    {
        label: "Urine Ware Collection Ⅱ",
        imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2019_7.png',
        description: "설명"
    },            
  ];
  
  export default uwc2;
  