import description from "../descriptionData/algatronDescription";

const algatron = [
    {
        label: "ALGATRON",
        imgPath: process.env.PUBLIC_URL + '/images/experiment_algatron_2017_1.png',
        description: description
    },
    {
        label: "ALGATRON",
        imgPath: process.env.PUBLIC_URL + '/images/experiment_algatron_2017_2.png',
        description: "설명"
    },
    {
        label: "ALGATRON",
        imgPath: process.env.PUBLIC_URL + '/images/experiment_algatron_2017_3.png',
        description: "설명"
    },
    {
        label: "ALGATRON",
        imgPath: process.env.PUBLIC_URL + '/images/experiment_algatron_2017_4.png',
        description: "설명"
    },
    {
        label: "ALGATRON",
        imgPath: process.env.PUBLIC_URL + '/images/experiment_algatron_2017_5.png',
        description: "설명"
    },
    {
        label: "ALGATRON",
        imgPath: process.env.PUBLIC_URL + '/images/experiment_algatron_2017_6.png',
        description: "설명"
    },
    {
        label: "ALGATRON",
        imgPath: process.env.PUBLIC_URL + '/images/experiment_algatron_2017_7.png',
        description: "설명"
    },            
  ];
  
  export default algatron;
  