import description from "../descriptionData/atlierDescription";

const atlier = [
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_1.png',
      description: description
    },
    {
        label: "아뜰리에로의 초대",
        imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_2.png',
        description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_3.png',
      description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_4.png',
      description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_5.png',
      description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_6.png',
      description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_7.png',
      description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_8.png',
      description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_9.png',
      description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_10.png',
      description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_11.png',
      description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_12.png',
      description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_13.png',
      description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_14.png',
      description: "설명"
    },
    {
      label: "아뜰리에로의 초대",
      imgPath: process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_15.png',
      description: "설명"
    },
];
  
  export default atlier;
  