const mu = [
    {
        label: "Manufactured Urine",
        imgPath: process.env.PUBLIC_URL + '/images/work_mu_2017_1.png',
        description: ""
    },
    {
        label: "Manufactured Urine",
        imgPath: process.env.PUBLIC_URL + '/images/work_mu_2017_2.png',
        description: "설명"
    },
    {
        label: "Manufactured Urine",
        imgPath: process.env.PUBLIC_URL + '/images/work_mu_2017_3.png',
        description: "설명"
    }  
  ];
  
  export default mu;
  