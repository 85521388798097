import '../App.css';
import Navigator from './Navigator';

function Contact() {
    return(
        <div style={{width:'100vw', height:'100vh' }}>
            <Navigator backgroundColor={'white'}></Navigator>
            <span>Contact</span>
        </div> 
    );
}

export default Contact;