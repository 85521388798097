import * as React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import styled from '@emotion/styled'
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";


function Navigator(props) {

    const CustomAppBar = styled(AppBar)({
        backgroundColor: "transparent",
        boxShadow: "none",
    });

    const subject = ["WORK", "ABOUT", "NEWS", "ARCHIVE", "CONTACT"];

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    };


    return(
        <div className='nav' style={{backgroundColor:props.backgroundColor}}>
            <CustomAppBar position="static" >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>                        
                        <Link to="/" className='title'>SinaeKim</Link>
                        {subject.map((subject) => (
                            <Link key={subject} to={"/" + subject.toLowerCase()} style={{marginLeft: "5%"}}><Typography fontSize={{xs: "0px", md: "12px"}}>{subject}</Typography></Link>
                        ))}  
                        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                            <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="black"
                            >
                            <MenuIcon />
                            </IconButton>
                            <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav ? anchorElNav : null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right"
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                                marginLeft: "57%"
                            }}
                            >
                            {subject.map((subject) => (
                                <MenuItem key={subject} onClick={handleCloseNavMenu}>
                                    <Link to={"/" + subject.toLowerCase()}><Typography textAlign="center">{subject}</Typography></Link>
                                </MenuItem>
                            ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </CustomAppBar>
        </div>    
    );
}

export default Navigator;