import '../App.css';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; 
import Navigator from './Navigator';
import text from './text';
import Footer from './Footer';

function About() {
    return(
        <div style={{ height:'auto' }}>
            <Navigator backgroundColor={'white'}></Navigator>
            <Container maxWidth="lg" className='aboutContainer'>
                <Grid container rowSpacing={4} columnSpacing={3} justifyContent="center" style={{marginTop:'10px'}}>
                    <Grid item xs={12} sm={5} md={5} className='aboutTextArea'>
                        {text.aboutMainKo}
                        {text.br}
                        {text.br}
                        {text.br}
                        {text.aboutMainEn}
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} className='aboutImageArea'>
                        <img className='aboutImage' src={process.env.PUBLIC_URL + '/images/sinaekim_01.png'} alt="실험"></img>
                        <img className='aboutImage' src={process.env.PUBLIC_URL + '/images/sinaekim_02.png'} alt="실험"></img>
                        <img className='aboutImage' src={process.env.PUBLIC_URL + '/images/sinaekim_03.png'} alt="워크샵"></img>
                        <img className='aboutImage' src={process.env.PUBLIC_URL + '/images/sinaekim_04.jpeg'} alt="교육"></img>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} fontSize="12px">
                        <p>
                            <span style={{fontSize: '12px'}}>
                                <em>EXHIBITION</em>
                            </span>
                        </p>
                        <br></br>
                        <hr></hr>
                        <p>
                            <em style={{color:'gray'}}>2024.2~2024.9</em>
                            <br></br>
                            -  BLUT & STAUB | Gewerbemuseum, Winterthur, Switzerland<br></br>
                        </p>
                        <br></br>
                        <p>
                            <em style={{color:'gray'}}>2023.10.21~2023.10.29</em>
                            <br></br>
                            -  The Living Archive | Dutch Design Week 2023, Eindhoven, NL<br></br>
                        </p>
                        <br></br>
                        <p>
                            <em style={{color:'gray'}}>2023.10.11~2023.10.20</em>
                            <br></br>
                            -  This is Urine (2023) | Space HNH, South Korea<br></br>
                        </p>
                        <br></br>
                        <p>
                            <em style={{color:'gray'}}>2023.9.21~2023.10.1</em>
                            <br></br>
                            -  School of Tomorrow | Singapore Design Week 2023, Selegie Arts Centre, Singapore<br></br>
                        </p>
                        <br></br>
                        <p>
                            <em style={{color:'gray'}}>2023.6.10~2023.6.18</em>
                            <br></br>
                            -  CICA New Media Art 2023 | CICA Museum, South Korea<br></br>
                        </p>
                        <br></br>
                        <p>
                            <em style={{color:'gray'}}>2023.2.3~2023.2.7</em>
                            <br></br>
                            -  Circular materials for future dinning | Frankfurt, Germany<br></br>
                        </p>
                        <br></br>
                        <p>
                            <em style={{color:'gray'}}>2022.12.1~2022.12.19</em>
                            <br></br>
                            -  Blut & Staub | Zurich University of the Arts, Zurich, Switzerland<br></br>
                        </p>
                        <br></br>
                        <p>
                            <em style={{color:'gray'}}>2022</em>
                            <br></br>
                            - Tradition:Transition at Punto Blu | Seoul, Korea<br></br>
                            - MaterialDistrict Utrecht at Werkspoorkathedraal | Utrecht, NL
                        </p>
                        <br></br>
                        <p>
                            <em style={{color:'gray'}}>2021</em>
                            <br></br>
                            - Taboo Materials : unconventional materials at Dutch Design Week | Eindhoven, NL<br></br>
                            - Design by Nature at the Museum de Fundatie | Zwolle, NL
                        </p>
                        <br></br>
                        <p>
                            <em style={{color:'gray'}}>2020</em>
                            <br></br>
                            - “Neomateria” by KIDP X Materfad | Korea<br></br>
                            - “Design Transfigured, Waste Reimagined” | Scottsdale Museum of Contemporary Art (SMoCA) | Arizona, USA
                        </p>
                        <br></br>
                        <p>
                            <em style={{color:'gray'}}>2019</em>
                            <br></br>
                            - SUBCONTRACTOR by Haut Innovation | Jönköping, Sweden<br></br>
                            - DESIGN TRANSFIGURED | Georgetown University Art Galleries | Washington D.C., USA<br></br>
                            - FOOD: Bigger than the plate | V&A Museum | London, UK<br></br>
                            -  Interzum fair by Haut Innovation | Cologne, Germany<br></br>
                            - “Neomateria at ARCHITECT@WORK” | Craft Centre of Catalonia | Barcelona, Spain<br></br>
                            - “Future Proof at ARCHITECT@WORK” | London, UK
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} fontSize="12px">
                        <p>
                            <span style={{fontSize: '12px'}}>
                                <em>PRESS</em>
                            </span>
                        </p>
                        <br></br>
                        <hr></hr>
                        <p>
                            ADF Web magazine, 'Design, Art, and the Human Body', Feb 2022
                        </p>
                        <br></br>
                        <p>
                            Craft Council, '7 makers and studios turning ceramics green', Oct 2021
                        </p>
                        <br></br>
                        <p>
                            The Hoya, 'New Walsh Gallery Challenges Conceptions of Art Materials', Oct 2019 
                        </p>
                        <br></br>
                        <p>
                            The Design Edit, 'Meet the designers exploring a new frontier of biological processes.', Aug 2019
                        </p>
                        <br></br>
                        <p>
                            Ndtv, 'This Designer is Making Ceramic Wares With Human Urine.', May 2019
                        </p>
                        <br></br>
                        <p>
                            Building Centre (Material Driven), '7 new materials made from waste products', May 2019
                        </p>
                        <br></br>
                        <p>
                            Daily Sun, 'This designer is making ceramic wares with human urine', May 2019 
                        </p>
                        <br></br>
                        <p>
                            Haute-innovation, 'This is Urine – tiles and dishes using human urine', May 2019 
                        </p>
                        <br></br>
                        <p>
                            MaterialDistrict, 'Ceramic Glaze Made from Human Urine', Jan 2019
                        </p>
                        <br></br>
                        <p>
                            StylePark, 'The materialists', Nov 2018
                        </p>
                        <br></br>
                        <p>
                            Dutch Design Week 2018, 'Making beautiful designs from dirty materials is a trend', Oct 2018
                        </p>
                        <br></br>
                        <p>
                            VICE (NL), 'Mooie spullen maken van vieze materialen is een trend', Oct 2018
                        </p>
                        <br></br>
                        <p>
                            Core77, 'Highlights from London Design Fair, Design Junction and Biodesign 2018', Sep 2018
                        </p>
                        <br></br>
                        <p>
                            Ignant, 'Destigmatizing Waste: Sinae Kim’s Ceramic Collection, Urine Ware', 2018
                        </p>
                        <br></br>
                        <p>
                            Cfile, 'Design | Covered in Piss, A Fresh Glaze Technique', July 2018
                        </p>
                        <br></br>
                        <p>
                            Elle Deco, 'UNOS JARRONES HECHOS CON... ¡ORINA!', July 2018
                        </p>
                        <br></br>
                        <p>
                            Dezeen, 'Central Saint Martins graduate creates decorative vessels glazed with human urine', Jun 2018
                        </p>
                        <br></br>
                        <p>
                            Material Lab, 'A SNEAK PEEK OF FUTURE MATERIAL EXPLORATIONS', Jan 2018
                        </p>
                        <br></br>
                        <p>
                            Best of 2019: Ceramic glaze made from human urine. (2019, December 21).
                        </p>
                        <br></br>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} fontSize="12px">
                        <p>
                            <span style={{fontSize: '12px'}}>
                                <em>WORKSHOP</em>
                            </span>
                        </p>
                        <br></br>
                        <hr></hr>
                        <p>
                            다원예술 2022 «미술관-탄소-프로젝트» 보다 지속가능한 예술실천을 위한 재료 워크숍, 국립현대미술관 (MMCA), Aug 2022
                        </p>
                        <br></br>
                        <p>
                            Taboo materials for a New Reality by Materfad, Feb/Jun/Nov 2021
                        </p>
                        <br></br>
                        <p>
                            <span style={{fontSize: '12px'}}>
                                <em>TALK</em>
                            </span>
                        </p>
                        <br></br>
                        <hr></hr>
                            <p>다원예술 2022 «미술관-탄소-프로젝트» 보다 지속가능한 예술실천을 위한 재료 논의, 국립현대미술관 (MMCA), Aug 2022</p>
                        
                        <br></br>
                        <br></br>
                        <p>
                            <span style={{fontSize: '12px'}}>
                                <em>ARCHIVES (TEXT)</em>
                            </span>
                        </p>
                        <br></br>
                        <hr></hr>
                            <p>HRE AFTA, 'Urine Ware'</p><br></br>
                            <p>Material District, 'Urine-Glaze'</p><br></br>
                            <p>Future Materials Bank, 'Urine'</p><br></br>
                            <p>Haeckels, 'Reusing Our Waste'</p><br></br>
                        <br></br>    
                        <br></br>    
                        <p>
                            <span style={{fontSize: '12px'}}>
                                <em>MATERIAL COLLECTION</em>
                            </span>
                        </p>
                        <br></br>
                        <hr></hr>
                            <p>The Building Centre/ Material Driven, UK 2019</p><br></br>
                            <p>GreenDsign, UK 2019</p><br></br>  
                            <p>Material District, NL 2019</p><br></br>  
                            <p>Materfad, Spain, Columbia, Mexico 2021</p><br></br>  
                            <p>Jan van Eyck Academie/ Future Materials Bank, NL 2022</p><br></br>  
                            <p>BioMateriOME, HBBE, UK 2022</p><br></br>
                            <p>Material Archiv, Swizerland/Germany 2022</p><br></br>                                                     
                    </Grid>
                </Grid>
            </Container>
            <Footer></Footer>
        </div> 
    );
}

export default About;