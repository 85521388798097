const uwc3 = [
    {
      label: "Urine Ware Collection Ⅲ",
      imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2021_1.png',
      description: ""
    },
    {
        label: "Urine Ware Collection Ⅲ",
        imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2021_2.png',
        description: "설명"
      }
  ];
  
  export default uwc3;
  