const uwc1 = [
    {
        label: "Urine Ware Collection Ⅰ",
        imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2018_1.png',
        description: ""
    },
    {
        label: "Urine Ware Collection Ⅰ",
        imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2018_2.png',
        description: "설명"
    },
    {
        label: "Urine Ware Collection Ⅰ",
        imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2018_3.png',
        description: "설명"
    },
    {
        label: "Urine Ware Collection Ⅰ",
        imgPath: process.env.PUBLIC_URL + '/images/work_uwc_2018_4.png',
        description: "설명"
    }     
  ];
  
  export default uwc1;
  