const newsObj = [
    {   
        type: "Exhibitions",
        date: "2022-12-01",
        imgPath: process.env.PUBLIC_URL + '/images/experiment_ug_2022_1.png',
        title: "Exhibition at Zurich University of the Arts",    
        description: "exhibited 'Urine Glaze' and 'Urine Ware', hosted by hosted by Material Archiv"
    },
    {   
        type: "Talk",
        date: "2022-08-27",
        imgPath: process.env.PUBLIC_URL + '/images/poster_workshop_kr_mmca_5.jpeg',
        title: "국립현대미술관 다원예술 2022 <미술관-탄소-프로젝트>",    
        description: "participated as a presenter in the 'Material Discussion and Workshop for Sustainable Art Practice'"
        + " co-hosted by the National Museum of Modern and Contemporary Art and The Future Materials Bank in the Netherlands."
    },
    {   
        type: "Exhibitions",
        date: "2022-04-04",
        imgPath: process.env.PUBLIC_URL + '/images/press_arch_md_2.png',
        title: "Exhibition by MaterialDistrict in Utrecht, NL",    
        description: "exhibited 'Urine Glaze' and 'Urine Ware', hosted by MaterialDistrict"
    },   
    {   
        type: "Exhibitions",
        date: "2022-07-17",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_kr_tr_1.png',
        title: "<Tradition: Transition> in Seoul, Korea",    
        description: "exhibited 'Urine Ware Collection lll' at the group exhibition hosted by UAL Korean Alumni."
    },   
    {   
        type: "Exhibitions",
        date: "2021.10.16",
        imgPath: process.env.PUBLIC_URL + '/images/workshop_pic_sp_mf_2019_1.jpeg',
        title: "<Taboo Materials: unconventional resources unfairly ignored> in Dutch Design Week 2021",    
        description: "exhibited 'Urine Glaze' by Materfad in Eindhoven, NL"
    }, 
    {   
        type: "Exhibitions",
        date: "2022.07.10",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_nl_mf_1.jpeg',
        title: "<Design by Nature> at Museum de Fundatie in Zwolle, NL",    
        description: "exhibited 'Urine Ware Collection ll' by BioBased Creation"
    }, 
    {   
        type: "Exhibitions",
        date: "2020-10-01",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_kr_mf_1.jpeg',
        title: "<Neomateria> in Korea",    
        description: "exhibited 'Urine Glaze' by KIDP (한국디자인진흥원) X Materfad"
    },     
    {   
        type: "Exhibitions",
        date: "2021-10-01",
        imgPath: process.env.PUBLIC_URL + '/images/logo_craftcouncil_1.png',
        title: "7 makers and studios turning ceramics green",    
        description: "an interview with Craft Council regarding sustainability in ceramics"
    }, 
    {   
        type: "Exhibitions",
        date: "2020-02-01",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_us_wc_1.jpeg',
        title: "<Design Transfigured/Waste Reimagined> at Scottsdale Museum of Contemporary Art, USA",    
        description: "exhibited 'Urine Ware Collection ll', curated by Curatorsquared"
    },    
    {   
        type: "Exhibitions",
        date: "2019-11-12",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_swd_interzum_3.jpeg',
        title: "<Materials Revolution 2019> in Jönköping, Sweden",    
        description: "exhibited 'Urine Ware', curated by Haut Innovation"
    },  
    {   
        type: "Exhibitions",
        date: "2019-10-03",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_us_wc_2.jpeg',
        title: "<Design Transfigured/Waste Reimagined> at Georgetown University Art Galleries, USA",    
        description: "exhibited 'Urine Ware Collection ll', curated by Curatorsquared"
    },    
    {   
        type: "Exhibitions",
        date: "2019-05-18",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_uk_va_2.jpeg',
        title: "<FOOD: Bigger than the plate> at V&A Museum, UK",    
        description: "exhibited 'Urine Ware Collection l', curated by V&A Museum"
    },  
    {   
        type: "Exhibitions",
        date: "2019-05-21",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_swd_interzum_2.jpeg',
        title: "<Disruptive Materials> at KölnMesse, Germany",    
        description: "exhibited 'Urine Ware Collection lI', curated by Haut Innovation"
    },  
    {   
        type: "Exhibitions",
        date: "2019-03-28",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_sp_wc_1.jpeg',
        title: "<Neomateria> at Architect@Work, Spain",    
        description: "exhibited 'Urine Glaze', curated by Materfad"
    },  
    {   
        type: "Exhibitions",
        date: "2019-01-30",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_uk_ug_1.png',
        title: "<Future Proof> at Architect@Work, London, UK",    
        description: "exhibited 'Urine Glaze', curated by MaterialDriven"
    }, 
    {   
        type: "Press",
        date: "2019-05-01",
        imgPath: process.env.PUBLIC_URL + '/images/logo_ndtv_1.png',
        title: "NDtv",    
        description: "an art work post, 'This Designer is Making Ceramic Wares With Human Urine.' in NDtv"
    }, 
    {   
        type: "Press",
        date: "2019-08-01",
        imgPath: process.env.PUBLIC_URL + '/images/logo_thedesignedit_1.png',
        title: "The Design Edit",    
        description: "An interview 'Meet the designers exploring a new frontier of biological processes.' in the Design Edit"
    }, 
    {   
        type: "Press",
        date: "2019-03-01",
        imgPath: process.env.PUBLIC_URL + '/images/building_centre.jpeg',
        title: "The Building Centre",    
        description: "a design post '7 new materials made from waste products' in the Building Centre"
    }, 
    {   
        type: "Press",
        date: "2019-03-01",
        imgPath: process.env.PUBLIC_URL + '/images/logo_hauteinnovation.jpeg',
        title: "Haut Innovation",    
        description: "a design post 'This is Urine – tiles and dishes using human urine' in Haut Innovation archives"
    }, 
    {   
        type: "Press",
        date: "2019-12-01",
        imgPath: process.env.PUBLIC_URL + '/images/logo_materialdistrict.png',
        title: "Material District",    
        description: "selected for the Best of 2019: Ceramic glaze made from human urine"
    },
    {   
        type: "Exhibitions",
        date: "2019-11-18",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_uk_oc_1.jpeg',
        title: "<Biodesign Here Now> at London Design Festival, UK",    
        description: "exhibited 'Urine Glaze', curated by Open Cell"
    },  
    {   
        type: "Exhibitions",
        date: "2018-10-20",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_dutch_dw_1.jpeg',
        title: "<United Matters> at Dutch Design Week, NL",    
        description: "exhibited 'Urine Glaze', curated by Future Materials team"
    },
    {   
        type: "Press",
        date: "2018-10-20",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_nl_ddw_1.png',
        title: "Dutch Design Week 2018",    
        description: "an interview 'Making beautiful designs from dirty materials is a trend' featured in DDW 2018"
    },
    {   
        type: "Exhibitions",
        date: "2018-04-17",
        imgPath: process.env.PUBLIC_URL + '/images/exhibition_milan_dw_1.jpeg',
        title: "<Material Futures> in Milan Design Week 2018",    
        description: "exhibited 'Urine Glaze', curated by Future Materials team"
    },
    {   
        type: "Press",
        date: "2018-07-01",
        imgPath: process.env.PUBLIC_URL + '/images/logo_cfile_1.png',
        title: "Cfileonline",    
        description: "a design post 'Design | Covered in Piss, A Fresh Glaze Technique' in Cfileonline archives"
    },
    {   
        type: "Press",
        date: "2018-07-01",
        imgPath: process.env.PUBLIC_URL + '/images/logo_elledecor_1.png',
        title: "Elle Deco",    
        description: "a design post 'UNOS JARRONES HECHOS CON... ¡ORINA' in Elle Deco magazine"
    },
    {   
        type: "Press",
        date: "2018-06-01",
        imgPath: process.env.PUBLIC_URL + '/images/logo_dezeen_1.png',
        title: "Dezeen",    
        description: "an interview 'Central Saint Martins graduate creates decorative vessels glazed with human urine' in Dezeen magazine"
    },
  ];
  
  export default newsObj;
  