const hair = [
    {
        label: "This is hair",
        imgPath: process.env.PUBLIC_URL + '/images/work_hair_2021_1.png',
        description: ""
    },
    {
        label: "This is hair",
        imgPath: process.env.PUBLIC_URL + '/images/work_hair_2021_2.png',
        description: "설명"
    },
    {
        label: "This is hair",
        imgPath: process.env.PUBLIC_URL + '/images/work_hair_2021_3.png',
        description: "설명"
    },
    {
        label: "This is hair",
        imgPath: process.env.PUBLIC_URL + '/images/work_hair_2021_4.png',
        description: "설명"
    },
    {
        label: "This is hair",
        imgPath: process.env.PUBLIC_URL + '/images/work_hair_2021_5.png',
        description: "설명"
    },
    {
        label: "This is hair",
        imgPath: process.env.PUBLIC_URL + '/images/work_hair_2021_6.png',
        description: "설명"
    },
    {
        label: "This is hair",
        imgPath: process.env.PUBLIC_URL + '/images/work_hair_2021_7.png',
        description: "설명"
    }          
  ];
  
  export default hair;
  