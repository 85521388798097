import React, { useState } from "react";
import '../App.css';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; 
import Navigator from './Navigator';
import workshopKrMmca from "./carouselData/workshopKrMmca";
import atlier from "./carouselData/atlier";
import ug from "./carouselData/ug";
import uwc1 from "./carouselData/uwc1";
import uwc2 from "./carouselData/uwc2";
import uwc3 from "./carouselData/uwc3";
import hair from "./carouselData/hair";
import mu from "./carouselData/mu";
import algatron from "./carouselData/algatron";
import Popup from "./Popup";
import Footer from "./Footer";


let steps = "";

function Work() {
  
    const [open, setOpen] = useState(false);

    const handleClickOpen = (type) => {

        switch (type) {
            case "PeeTrading":  steps = workshopKrMmca; break;
            case "Atlier":      steps = atlier;         break;
            case "UWC1":        steps = uwc1;           break;
            case "UWC2":        steps = uwc2;           break;
            case "UWC3":        steps = uwc3;           break;
            case "UG":          steps = ug;             break;
            case "Hair":        steps = hair;           break;
            case "MU":          steps = mu;             break;
            case "Algatron":    steps = algatron;       break;
            default:                                    break;
        }

        setOpen(true);
    };

    return(
        <div style={{ height:'auto' }}>
            <Navigator backgroundColor={'white'}></Navigator>
            <Container maxWidth="lg" className='workContainer'>
                <Grid container rowSpacing={8} columnSpacing={8} justifyContent="center" style={{marginTop: "-35px"}}>
                    
                    <Popup open = {open} setOpen={setOpen} steps={steps}>
                    </Popup>

                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images cursor' src={process.env.PUBLIC_URL + '/images/workshop_kr_mmca_2.png'} alt="Pee Trading (소변세稅의 현대적 재현)" 
                        onClick={()=>{handleClickOpen("PeeTrading")}}></img>
                        <span style={{fontSize: "13px"}}>Pee Trading (소변세稅의 현대적 재현)</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2022</span>
                    </Grid>


                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images' src={process.env.PUBLIC_URL + '/images/poster_workshop_kr_mmca_4.png'} alt="미술관-탄소-프로젝트"></img>
                        <span style={{fontSize: "13px"}}>미술관-탄소-프로젝트</span><br></br>
                        <span style={{fontSize: "13px"}}>보다 지속가능한 예술신천을 위한 재료 논의</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2022</span>
                    </Grid>
                    
                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images cursor' src={process.env.PUBLIC_URL + '/images/education_atlier_sa_2022_1.png'} alt="아뜰리에로의 초대" 
                        onClick={()=>{handleClickOpen("Atlier")}}></img>
                        <span style={{fontSize: "13px"}}>아뜰리에로의 초대</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2022</span>
                    </Grid>   

                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images' src={process.env.PUBLIC_URL + '/images/work_ap_graphic_2022_2.png'} alt="Accumulated Self-portrait 001-200"></img>
                        <span style={{fontSize: "13px"}}>Accumulated Self-portrait 001-200</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2021~2022</span>
                    </Grid>

                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images' src={process.env.PUBLIC_URL + '/images/work_dailyritual_2022_1.png'} alt="Daily Ritual 001-232"></img>
                        <span style={{fontSize: "13px"}}>Daily Ritual 001-232</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2021~2022</span>
                    </Grid>

                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images cursor' src={process.env.PUBLIC_URL + '/images/work_uwc_2021_1.png'} alt="Urine Ware Collection Ⅲ" 
                        onClick={()=>{handleClickOpen("UWC3")}}></img>
                        <span style={{fontSize: "13px"}}>Urine Ware Collection Ⅲ</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2021</span>
                    </Grid>   

                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images cursor' src={process.env.PUBLIC_URL + '/images/work_hair_2021_1.png'} alt="This is hair" 
                        onClick={()=>{handleClickOpen("Hair")}}></img>
                        <span style={{fontSize: "13px"}}>This is hair</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2021</span>
                    </Grid> 

                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images cursor' src={process.env.PUBLIC_URL + '/images/work_uwc_2019_1.png'} alt="Urine Ware Collection Ⅱ" 
                        onClick={()=>{handleClickOpen("UWC2")}}></img>
                        <span style={{fontSize: "13px"}}>Urine Ware Collection Ⅱ</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2019</span>
                    </Grid>

                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images cursor' src={process.env.PUBLIC_URL + '/images/work_uwc_2018_1.png'} alt="Urine Ware Collection Ⅰ" 
                        onClick={()=>{handleClickOpen("UWC1")}}></img>
                        <span style={{fontSize: "13px"}}>Urine Ware Collection Ⅰ</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2018</span>
                    </Grid>

                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images cursor' src={process.env.PUBLIC_URL + '/images/progress_ug_2022_1.png'} alt="Urine Glaze" 
                        onClick={()=>{handleClickOpen("UG")}}></img>
                        <span style={{fontSize: "13px"}}>Urine Glaze</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2018</span>
                    </Grid>

                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images' src={process.env.PUBLIC_URL + '/images/video_img_tiu_2018_2.png'} alt="This is urine"></img>
                        <span style={{fontSize: "13px"}}>This is urine</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2018</span>
                    </Grid>

                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images cursor' src={process.env.PUBLIC_URL + '/images/work_mu_2017_1.png'} alt="Manufactured Urine" 
                        onClick={()=>{handleClickOpen("MU")}}></img>
                        <span style={{fontSize: "13px"}}>Manufactured Urine</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2017</span>
                    </Grid>

                    <Grid item xs={12} sm={3.5} md={3.5}>
                        <img className='images cursor' src={process.env.PUBLIC_URL + '/images/experiment_algatron_2017_0.png'} alt="ALGATRON" 
                        onClick={()=>{handleClickOpen("Algatron")}}></img>
                        <span style={{fontSize: "13px"}}>ALGATRON</span><br></br>
                        <span style={{fontSize: "10px", color:"GrayText"}}>2017</span>
                    </Grid>

                    <Grid item xs={12} sm={3.5} md={3.5}>
                    </Grid>

                    <Grid item xs={12} sm={3.5} md={3.5}>
                    </Grid>
                </Grid>
            </Container>
            <Footer></Footer>    
        </div> 
    );
}

export default Work;