let description = ""
+ "<Invitation to Atelier> is a project that develops art education programs in collaboration with teenagers. Bioart was the main subject for the program."
+ "\n\n"
+ "Bioart is a form of creative expression that incorporates scientific techniques and draws upon the philosophical, societal, and environmental impacts of recombinant genetics, molecular biology, and biotechnology."
+ "Some bioartists strive to bridge the gap between art and science by fostering interdisciplinary collaborations."
+ "\n\n"
+ "It needs “multi literacy” which is highlighted in the new curriculum for schools nowadays."
+ "Multi-literacy refers to the ability to comprehend the fundamental concepts and discussions across diverse disciplines."
+ "In today's fast-paced and multi-tasking society, a broad base of knowledge is valued, but this can sometimes lead to a lack of focus on specialization and in-depth analysis."
+ "\n\n"
+ "By working together as experts in their respective fields, artists and scientists can bring fresh perspectives to their areas of expertise, exploring uncharted territories."
+ "They can even embark on unconventional concepts, such as creating leather from mushrooms."
+ "\n\n"
+ "The convergence of artistic thinking and scientific experimentation in bioart raises new questions, leading to the consideration of creative solutions for interdisciplinary problem-solving."
+ "This flexibility of creative thinking enables discussions around personal and societal issues, allowing for more proactive and self-directed actions in future societies."
+ "\n\n\n\n\n\n\n\n"
+ "<아뜰리에로의 초대>는 청소년과의 공동창작 과정을 통해 예술교육 프로그램을 연구/실행하는 사업으로, 바이오아트(bioart)를 주된 주제로 한 프로젝트가 진행되었습니다."
+ "\n\n"
+ "바이오 아트(bio-art)란 예술과 과학 및 기술이 접목된 다학적 창의융합분야입니다."
+ "교육의 선진국이라 불리는 핀란드, 영국, 네덜란드, 미국 등을 중심으로 수년전부터 활발히 논의되어 이미 대학교에 학부가 설립되어 커리큘럼으로 실행되고 있습니다."
+ "그리고  그 중요성이 계속 커져 현재는 청소년 아이들을 위한 다양한 교육 프로그램들이 개발 및 시행되고 있습니다. 이는 예술과 과학을 결합하는 형태의 교육이 주목받는 것은 단순한 교육 트렌드가 아님을 의미합니다."
+ "\n\n"
+ "최근 들어 한국에서도 예술과 과학이 접목된 창의융합교육(Art & Science Education)의 중요성이 날로 커지고 있습니다."
+ "이와 동시에 멀티 리터러시 (multi-literacy) 교육에 대한 관심도 커지고 있는데요, 멀티 리터러시란 다양한 주제 및 분야에 대한 기초와 담론들을 이해할 수 있는 ‘다중 문해 능력’입니다."
+ "이러한 폭넓은 지식 기반의 능력이 멀티 태스킹이 불가피한 현대사회에서 인정받기도 하는데, 이는 높은 수준의 심층적인 전문성이 때로는 불필요해질 정도입니다."
+ "\n\n"
+ "이러한 사회적 현상은 예술가와 과학자 또는 기술자가 서로 협업하게 하며, 새로운 관점으로 혁신적인 결과가 나오기도 합니다. 이런 협업 방식은 이전에는 생각되지 않았던 것입니다."
+ "예를 들어, 하나의 단일 세포에서 먹을 수 있는 스테이크를 배양하는 등 엉뚱한 아이디어들을 다양하고 창의적인 실험 과정을 거쳐 구현해 내며, 이는 사회, 문화, 환경 문제에 대한 해결책이 됩니다."
+ "\n\n"
+ "전형적인 과학 실험은 이미 연구되어진 가설을 검증하기 위한 도구로서 쓰입니다."
+ "하지만, 바이오 아트에서의 예술적 사고와 과학적 실험의 융합은 새로운 문제를 제기하여 다학적 문제 해결을 위해 ‘내’가 할 수 있는 창의적인 방법들을 생각하게 합니다."
+ "이러한 창의적 사고의 유연함은 개인 및 사회를 둘러싼 지역 및 문화적인 토론을 가능하게 하고, 앞으로의 사회에서 더욱 능동적이고 자발적인 활동을 할 수 있게 해줍니다.";

export default description;