const ug = [
    {
        label: "Urine Glaze",
        imgPath: process.env.PUBLIC_URL + '/images/work_tiu_2018_1.png',
        description: ""
    },
    {
        label: "Urine Glaze",
        imgPath: process.env.PUBLIC_URL + '/images/work_tiu_2018_2.png',
        description: "설명"
    },
    {
        label: "Urine Glaze",
        imgPath: process.env.PUBLIC_URL + '/images/progress_ug_2022_1.png',
        description: "설명"
    },
    {
        label: "Urine Glaze",
        imgPath: process.env.PUBLIC_URL + '/images/progress_ug_2018_1.png',
        description: "설명"
    }     
  ];
  
  export default ug;
  