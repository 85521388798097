import React, { useState }  from "react";
import { makeStyles, useTheme } from "@mui/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from "react-swipeable-views-utils";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Description from "./Description";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    flexGrow: 1
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 150,
    backgroundColor: theme.palette.background.default
  },
  img: {
    height: 530,
    display: "block",
    maxWidth: 900,
    overflow: "hidden",
    width: "auto",
    margin: "auto"
  }
}));

function Carousel(props) {
    const { steps } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
    setActiveStep(step);
    };

    return (
    <div className={classes.root}>
        <AutoPlaySwipeableViews
        className={activeStep === maxSteps - 1 ? null:"cursor"}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={500000}
        autoPlay={false}
        onClick={activeStep === maxSteps - 1 ? null:handleNext}
        >
        {steps.map((step, index) => (
            <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
                <img
                className={classes.img + " sxCarouselImg"}
                src={step.imgPath}
                alt={step.label}
                />
            ) : null}
            </div>
        ))}
        </AutoPlaySwipeableViews>
        <Paper elevation={0} className={classes.header}>
        <Grid container direction="column" alignItems="center">
            <Typography variant="h5" gutterBottom>
            {steps[activeStep].label}
            </Typography>
            <Grid
            container
            justify="center"
            style={{ padding: "0px 24px 0px 24px" }}
            >
            {steps[0].description !== "" ? <Button className="lightGray marginAuto" size="samll" margin="auto" onClick={handleClickOpen}>Text</Button> : ""}
            <Dialog onClose={handleClose} open={open} fullScreen>
                <DialogActions>  
                    <Button className="lightGray marginAuto" onClick={handleClose}>Image</Button>
                </DialogActions>
                <DialogContent>
                    <Description description={steps[0].description}></Description>
                </DialogContent>
            </Dialog>
            </Grid>
        </Grid>
        </Paper>
        <MobileStepper
        className="lightGray"
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
            <Button className="lightGray minusMarginR4" 
                size="small" 
                onClick={handleNext} 
                disabled={activeStep === maxSteps - 1}
            >
            Next
            {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
            ) : (
                <KeyboardArrowRight />
            )}
            </Button>
        }
        backButton={
            <Button className="lightGray minusMarginL4" 
                size="small" 
                onClick={handleBack} 
                disabled={activeStep === 0}
                margin="0 0 0 -23%"
                >
            {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
            ) : (
                <KeyboardArrowLeft />
            )}
            Back
            </Button>
        }
        sx={{WebkitJustifyContent: "space-around"}}
        />
    </div>
    );
}

export default Carousel;
