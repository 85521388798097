import '../App.css';
import React, { useState } from "react";
import Navigator from './Navigator';
import Container from '@mui/material/Container';
import Popup from "./Popup";
import workshopKrMmca from "./carouselData/workshopKrMmca";
import atlier from "./carouselData/atlier";
import ug from "./carouselData/ug";
import uwc1 from "./carouselData/uwc1";
import uwc2 from "./carouselData/uwc2";
import uwc3 from "./carouselData/uwc3";
import hair from "./carouselData/hair";
import mu from "./carouselData/mu";
import algatron from "./carouselData/algatron";

let steps = "";

function Archive() {
    
    const [open, setOpen] = useState(false);

    const handleClickOpen = (type) => {

        switch (type) {
            case "PeeTrading":  steps = workshopKrMmca; break;
            case "Atlier":      steps = atlier;         break;
            case "UWC1":        steps = uwc1;           break;
            case "UWC2":        steps = uwc2;           break;
            case "UWC3":        steps = uwc3;           break;
            case "UG":          steps = ug;             break;
            case "Hair":        steps = hair;           break;
            case "MU":          steps = mu;             break;
            case "Algatron":    steps = algatron;       break;
            default:                                    break;
        }

        setOpen(true);
    };
    
    return(
        <div style={{width:'100vw', height:'100vh', overflow: 'hidden'}}>
            <Navigator backgroundColor={'white'}></Navigator>
            <Container maxWidth="lg" className='archiveContainer'>
                <Popup open = {open} setOpen={setOpen} steps={steps}>
                </Popup>
                <p>
                    <span className='arcYear'>2022</span><br></br>
                        <span className='arcTitle cursor' onClick={()=>{handleClickOpen("PeeTrading")}}>Pee Trading(소변세稅의 현대적 재현)</span><br></br>
                        <span className='arcTitle'>미술관-탄소-프로젝트</span><br></br>
                        <span className='arcTitle cursor' onClick={()=>{handleClickOpen("Atlier")}}>아뜰리에로의 초대</span><br></br>
                </p>
                <p>
                    <span className='arcYear'>2021-2022</span><br></br>
                        <span className='arcTitle'>Accumulated Self-portrait 001-200</span><br></br>
                        <span className='arcTitle'>Daily Ritual 001-232</span><br></br>
                </p>
                <p>
                    <span className='arcYear'>2021</span><br></br>
                        <span className='arcTitle cursor' onClick={()=>{handleClickOpen("UWC3")}}>Urine Ware Collection Ⅲ</span><br></br>
                        <span className='arcTitle cursor' onClick={()=>{handleClickOpen("Hair")}}>This is hair</span><br></br>
                </p>
                <p>
                    <span className='arcYear'>2019</span><br></br>
                        <span className='arcTitle cursor' onClick={()=>{handleClickOpen("UWC2")}}>Urine Ware Collection Ⅱ</span><br></br>
                </p>
                <p>
                    <span className='arcYear'>2018</span><br></br>
                        <span className='arcTitle cursor' onClick={()=>{handleClickOpen("UWC1")}}>Urine Ware Collection Ⅰ</span><br></br>
                        <span className='arcTitle cursor' onClick={()=>{handleClickOpen("UG")}}>Urine Glaze</span><br></br>
                        <span className='arcTitle'>This is urine</span><br></br>
                </p>
                <p>
                    <span className='arcYear'>2017</span><br></br>
                        <span className='arcTitle cursor' onClick={()=>{handleClickOpen("MU")}}>Manufactured Urine</span><br></br>
                        <span className='arcTitle cursor' onClick={()=>{handleClickOpen("Algatron")}}>ALGATRON</span><br></br>
                </p>
            </Container>
        </div> 
    );
}

export default Archive;