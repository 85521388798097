 let text = {
    br : "\n",

    aboutMainKo : 
    "머테리얼 연구자로서 김시내 작가는 종종 실험 과정을 통해 과학과 예술 및 디자인의 관계를 탐구한다." 
    +"그녀는 물질의 활용가치를 문화적, 역사적, 환경적인 관점에서 재조명하며 현대적으로 쓰여질 수 있는 물질의 잠재성을 재해석한다."
    +"특히 인체에서 생성되어 버려지는 노폐물(human waste)의 잠재성에 뜻밖의 심미적 가치를 부여함으로써 사람들이 그것을 인식하는 데에 기준이 되는 사회・문화적 고정관념에 큰 물음표를 던지는 작업을 하고 있다."
    +"이는 인간이 원료(raw materials)를 만들어내는 주체적 생산자로서 주변의 다양한 생명체들과 다양한 방식으로 공생하며 지낼 수 있는 가능성을 열어준다." 
    +"다루는 물질의 범위를 박테리아, 녹조류, 곰팡이 등 살아있는 물질(bio materials)로 확대하여 인간과 자연과의 공생 작업을 계속하여 이어나갈 예정이다.",

    aboutMainEn : 
    "As a material artist and a researcher, Sinae Kim mostly take materiality as the starting point of the artistic process."
    +"She mainly focuses on exploring how we will live in the future through transdisciplinary practices and collaborations."
    +"As a result, her projects always include experimentation, innovation, contextual and critical knowledge and art-led personal research."
    +"By taking risks and blurring the boundaries between art, science and technology, she pursues unorthodox ways to look beyond existing disciplines, anticipating the future needs, desires and challenges that we face in our societies."
    +"She always looks for provocative ideas to tackle social obstacles which limit the potential use of materials and how people perceive them."
}

export default text;