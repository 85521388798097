import description from "../descriptionData/workshopKrMmcaDescription";

const workshopKrMmca = [
    {
      label: "소변세稅의 현대적 재현",
      imgPath: process.env.PUBLIC_URL + '/images/workshop_kr_mmca_2.png',
      description: description
    },
    {
        label: "소변세稅의 현대적 재현",
        imgPath: process.env.PUBLIC_URL + '/images/workshop_kr_mmca_3.png',
        description: "설명"
      }
  ];
  
  export default workshopKrMmca;
  