import React, { useState } from "react";
import '../App.css';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; 
import Navigator from './Navigator';
import newsObj from "./newsData/newsObj";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Footer from "./Footer";

function News() {

    const [type, setType] = useState("");
    const [alignment, setAlignment] = React.useState('left');

    let dateSortedObj;
    dateSortedObj = newsObj.sort( (a, b) =>  new Date(a.date) - new Date(b.date) ).reverse();

    let newsContents = (type) => {

        let html = [];

        dateSortedObj.map((dateSortedObj, index)=>{
            
           
            if(type === ""){
                html.push( getNewsGrid(dateSortedObj, index) );
            }else if(type === "Talk"){
                if(dateSortedObj.type === type){
                    html.push( getNewsGrid(dateSortedObj, index) );
                }        
            }else if(type === "Press"){
                if(dateSortedObj.type === type){
                    html.push( getNewsGrid(dateSortedObj, index) );
                }        
            }else if(type === "Exhibitions"){
                if(dateSortedObj.type === type){
                    html.push( getNewsGrid(dateSortedObj, index) );
                }        
            } 
                return html; 
        });    

        return html;
            
    };

    let getNewsGrid = (obj, index) => {
        return  <Grid item xs={12} sm={3.5} md={3.5} key={index}>
                    <img className='newsImages' src={obj.imgPath} alt={newsObj.title}></img>
                    <span style={{fontSize:13}}>{obj.title}</span><br></br>
                    <span style={{fontSize:10}}>{obj.description}</span><br></br>
                    <span style={{fontSize:10, color:'GrayText'}}>{obj.date}</span>
                </Grid>;
    };

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return(
        <div style={{ height:'auto' }}>
            <Navigator backgroundColor={'white'}></Navigator>
            <Container maxWidth="lg" className='newsContainer'>
                <ToggleButtonGroup 
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    sx={{float: "right", marginRight: "7%"}}
                    className="newsNav">
                    <ToggleButton className="lightGray btnStyle1" onClick={()=>{setType("")}} value="Latest">Latest</ToggleButton>
                    <ToggleButton className="lightGray btnStyle1" onClick={()=>{setType("Exhibitions")}} value="Exhibitions">Exhibitions</ToggleButton>
                    <ToggleButton className="lightGray btnStyle1" onClick={()=>{setType("Press")}} value="Press">Press</ToggleButton>
                    <ToggleButton className="lightGray btnStyle1" onClick={()=>{setType("Talk")}} value="Talk">Talk</ToggleButton>
                </ToggleButtonGroup>            
                <Grid container rowSpacing={8} columnSpacing={8} justifyContent="center" style={{marginTop: "-35px"}}>
                    { newsContents(type) }
                </Grid>
            </Container>
            <Footer></Footer>
        </div> 
    );
}

export default News;