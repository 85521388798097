import React from "react";
import Dialog from '@mui/material/Dialog';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Carousel from "./Carousel";
import Button from "@mui/material/Button";

function Popup(props) {
    
    const { open, setOpen, steps } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const theme = createTheme({
        root: {
            padding: "5px"
        }
      });

    return(
        <div>
            <Dialog onClose={handleClose} open={open} fullScreen>
                <ThemeProvider theme={theme}>
                    <DialogActions>  
                        <Button className="lightGray" onClick={handleClose}>Close</Button>
                    </DialogActions>
                    <DialogContent className="marginAuto" sx={{height:{xs: "50%"}}}>
                        <Carousel steps={steps} />
                    </DialogContent>
                </ThemeProvider>    
            </Dialog>
        </div>
    );
}

export default Popup;