import React from "react";
import Container from '@mui/material/Container';

function Description(props) {

    return(
        <Container maxWidth="lg" className="descriptionContainer">
            {props.description}
        </Container>
    );
}

export default Description;